require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("@fortawesome/fontawesome-free/js/all");

import _ from 'lodash'
import Vue from 'vue'
import { AlertPlugin } from 'bootstrap-vue'
Vue.use(AlertPlugin);

import store from "../store/application_store";
window.application_store = store

document.addEventListener('DOMContentLoaded', () => {
  const flashMessages = new Vue({
    el: '#flash-messages',
    store,
    methods: {
      resetFlash () {
        this.$store.commit('setFlash', {message: {
            notice: null,
            alert: null
          }})
      }
    }
  })
})
