import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  state: {
    message: {
      notice: null,
      alert: null
    }
  },
  mutations: {
    setFlash(state, payload) {
      state.message = payload.message
    }
  }
  // strict: debug
})
